import React, { Component, useState } from "react";


import "./auth-layout.scss";
import { Link } from "react-router-dom";
import { AuthSideInfoContainer } from "../../base/AuthSideInfoContainer/AuthSideInfoContainer";
interface IAuthLayoutProps {
    children: React.ReactNode;
}
export const AuthLayout = (props: IAuthLayoutProps) => {
    return (
        <div className="auth-layout vh-100">
            <div className="row w-100 h-100">
                <div className="col-5 bg-white d-flex justify-content-center align-items-center">
                    {props.children}
                </div>
                <div className="col-7 px-0">
                    <AuthSideInfoContainer heading={"Art of Scale Corporate Panel"} img={"/images/auth-side.svg"} />
                </div>
            </div>
            {/* <img src={logo} height={} /> */}


        </div>
    );
}


export default AuthLayout;
