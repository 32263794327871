import React, { Component, useState } from "react";
import { Button } from "reactstrap";
import StandardInput from "../../../form_components/StandardInput/StandardInput";
import validationUtils from "../../../utilities/validationUtils";
import ForgotPasswordValidation from "./ForgotPasswordValidation";
import Axios, { NodeAxios } from "../../../utilities/apiUtils";
import showNotification from "../../../utilities/notificationUtils";
import { setAccessToken } from "../../../utilities/tokenUtils";
import AuthLayout from "../../../containers/AuthLayout/AuthLayout";

export const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        email: ""
    })
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const onChange = (value: any, name: string) => {
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!validationUtils.checkFormValidity(formData, ForgotPasswordValidation)) {
            setShowError(true);
            return;
        }
        setShowError(false);
        setIsLoading(true);
        NodeAxios.post(`/auth/forgetPassword/${formData.email}`)
            .then((response) => {
                setIsLoading(false);
                showNotification("success", "Reset password link sent to your email");
            })
            .catch((error) => {
                setIsLoading(false);
                showNotification("error", error.response.data.message);
            });
    };
    return (
        <AuthLayout>
            {/* <img src={logo} height={} /> */}

            <form onSubmit={onSubmit}>
                <h2 className="mb-4 px-5">Get Reset Link</h2>
                <div className="mb-3">
                    <StandardInput
                        value={formData.email}
                        type="text"
                        placeholder="Your email"
                        name="email"
                        onChange={onChange}
                        validations={ForgotPasswordValidation.email}
                        showError={showError}
                    />
                </div>                
                <div>
                    <Button
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                        className="login-button w-100 btn-lg"
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm text-light mr-2"
                                    role="status"
                                ></span>
                            ) : null}
                            Send me the link !
                        </div>
                    </Button>
                </div>
            </form>
            </AuthLayout>
    );
}



export default ForgotPassword;
