import React, { Component, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { PublicRoute } from "./base/PublicRoute";
import Login from "./views/Auth/Login/Login";
import Signup from "./views/Auth/Signup/Signup";
import { ProtectedRoute } from "./base/ProtectedRoute";
import VerifyOTP from "./views/Auth/VerifyOTP/VerifyOTP";
import ForgotPassword from "./views/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/Auth/ResetPassword/ResetPassword";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
// const Login = React.lazy(() => import("./views/LMS/Auth/Login"));

function App() {
  useEffect(() => {
    if (window.location.href.includes("nxt-9.com")) {
      window.location.href = window.location.href.replace("nxt-9.com", "artofscale.io");
    }
  }, [])
  return <BrowserRouter>
    <ToastContainer pauseOnFocusLoss={false} />
    <React.Suspense fallback={loading()}>
      <Switch>
        <PublicRoute
          path="/auth/login"
          name="Admin Login"
          component={Login}
        />
        <PublicRoute
          path="/auth/signup"
          name="Admin Login"
          component={Signup}
        />
        <ProtectedRoute
          path="/auth/verify-otp"
          name="Admin Login"
          component={VerifyOTP}
        />
        <PublicRoute
          path="/auth/forgot-password"
          name="Admin Login"
          component={ForgotPassword}
        />
        <PublicRoute
          path="/auth/reset-password/:resetCode"
          name="Admin Login"
          component={ResetPassword}
        />
        {/* <PublicRoute
          path="/signup"
          name="Admin Signup"
          component={Signup}
        /> */}
        <Route
          path="/"
          name="Home"
          render={(props) => <DefaultLayout {...props} />}
        />
      </Switch>
    </React.Suspense>
  </BrowserRouter>
}


export default App;
