import React, { Component, useEffect, useState } from "react";
import { Button } from "reactstrap";
import StandardInput from "../../../form_components/StandardInput/StandardInput";
import "./signup.scss";
import validationUtils from "../../../utilities/validationUtils";
import SignupValidation from "./SignupValidation";
import Axios, { NodeAxios } from "../../../utilities/apiUtils";
import showNotification from "../../../utilities/notificationUtils";
import { setAccessToken } from "../../../utilities/tokenUtils";
import LoadingAnimation from "../../../utilities/LoadingAnimation";
import { AxiosError } from "axios";
import queryString from 'query-string';
import { IInvitedUser } from "../interface";
import StandardPhoneInput from "../../../form_components/StandardPhoneInput/StandardPhoneInput";
import AuthLayout from "../../../containers/AuthLayout/AuthLayout";


export const Signup = () => {
  const [formData, setFormData] = useState<IInvitedUser>({
    email: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    password: "",
  })
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);

  let params = queryString.parse(window.location.search)
  let inviteCode = params.inviteCode;

  const onChange = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateInviteCode = async () => {
    try {
      if (!inviteCode) {
        return showNotification("error", "Invalid invite code");
      }
      let invitedUser: {
        data: IInvitedUser
      } = await NodeAxios.get("/corporate-panel/auth/validate-invite-code", { params: { inviteCode } })
      setFormData(invitedUser.data);
    } catch (error: AxiosError | any) {      
      showNotification("error", error.response.data.message);
    }
    setIsLoading(false);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    console.log(formData, SignupValidation);

    if (!validationUtils.checkFormValidity(formData, SignupValidation)) {
      setShowError(true);
      return;
    }
    setShowError(false);
    setIsSubmitting(true);
    NodeAxios.post("/corporate-panel/auth/signup", {...formData, inviteCode })
      .then((response) => {
        setAccessToken(response.data.data.accessToken);
        window.location.href = "/";
      })
      .catch((error) => {
        showNotification("error", error.response.data.message);
      }).finally(() => {
        setIsSubmitting(false);
      })
  };

  useEffect(() => {
    validateInviteCode();
  }, []);

  if (isLoading) {
    return <div className="login-container"><LoadingAnimation /></div>
  }
  return (
    <AuthLayout>
      {/* <img src={logo} height={} /> */}
      <form onSubmit={onSubmit}>
        <h2 className="mb-4">Corporate Admin Signup</h2>
        <div className="mb-3">
          <StandardInput
            value={formData.email}
            type="text"
            placeholder="Your email"
            name="email"
            onChange={onChange}
            validations={SignupValidation.email}
            showError={showError}
          />
        </div>
        <div className="mb-3">
          <StandardInput
            value={formData.firstName}
            type="text"
            placeholder="First Name"
            name="firstName"
            onChange={onChange}
            validations={SignupValidation.firstName}
            showError={showError}
          />
        </div>
        <div className="mb-3">
          <StandardInput
            value={formData.lastName}
            type="text"
            placeholder="Last Name"
            name="lastName"
            onChange={onChange}
            validations={SignupValidation.lastName}
            showError={showError}
          />
        </div>
        <div className="mb-3">
          <StandardPhoneInput
            value={formData.phoneNo}
            type="text"
            placeholder="Phone number"
            name="phoneNo"
            onChange={onChange}
            validations={SignupValidation.phoneNo}
            showError={showError}
          />
        </div>
        <div className="mb-3">
          <StandardInput
            value={formData.password}
            type="password"
            name="password"
            placeholder="Your password"
            onChange={onChange}
            validations={SignupValidation.password}
            showError={showError}
          />
        </div>
        <div>
          <Button
            color="primary"
            disabled={isSubmitting}
            type="submit"
            className="login-button w-100 btn-lg"
          >
            <div className="d-flex justify-content-center align-items-center">
              {isSubmitting ? (
                <span
                  className="spinner-border spinner-border-sm text-light mr-2"
                  role="status"
                ></span>
              ) : null}
              Signup
            </div>
          </Button>
        </div>
      </form>
      </AuthLayout>
  );
}

export default Signup;
