import ValidationEnum from "../../../enums/ValidationsEnum";

export default {  
  password: [
    {
      type: ValidationEnum.REQUIRED,
      message: "Password is required",
    },
  ]  
};
