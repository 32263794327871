import axios from "axios";
import { getAccessToken, removeAccessToken } from "./tokenUtils";
import { clearCookie } from "./cookieUtils";

const apiURL = process.env.REACT_APP_LMS_API_URL;
const NodeApiURL = process.env.REACT_APP_NODE_API_URL;
const coachApiURL = process.env.REACT_APP_COACH_API_URL;

// LMS api instance
const Axios = axios.create({
  baseURL: apiURL,
});
const NodeAxios = axios.create({
  baseURL: NodeApiURL,
});

// 10x SME / Coach api instance
const AxiosCoach = axios.create({
  baseURL: coachApiURL,
});

Axios.interceptors.request.use(
  (config) => {
    const token_type = "Bearer";
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = `${token_type} ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


NodeAxios.interceptors.request.use(
  (config) => {
    const token_type = "Bearer";
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = `${token_type} ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

AxiosCoach.interceptors.request.use(
  (config) => {
    const token_type = "Bearer";
    const token = getAccessToken();    
    if (token) {
      config.headers["Authorization"] = `${token_type} ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

AxiosCoach.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401 && error.config.url !== "/auth/login") {    
    removeAccessToken();
    window.location.href = "/auth/login";
  } else {
    return Promise.reject(error);
  }
});

NodeAxios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401 && error.config.url !== "/auth/login") {    
    removeAccessToken();
    window.location.href = "/auth/login";
  } else {
    return Promise.reject(error);
  }
});

export { AxiosCoach, NodeAxios };
export default Axios;
