import React from "react";
import "./auth-side-info-container.scss";

export interface IAuthSideInfoContainerProps {
    img: string;
    heading: string;
    text?: string;
    imgStyle?: React.CSSProperties;
}
export const AuthSideInfoContainer = (props: IAuthSideInfoContainerProps) => {
    return (
        <>
            <div className="auth-side-info-container text-center d-flex align-items-center justify-content-center flex-column w-100">
                <div className="d-flex justify-content-center align-items-center">
                    <img
                        src={props.img}
                        alt="img"
                        className="img-fluid"
                        style={props.imgStyle}
                    />
                </div>
                <h2 className="mt-3 big">{props.heading}</h2>
                <div className="mt-3 cs-content">{props.text}</div>
            </div>
        </>
    );
};
