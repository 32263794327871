import React, { Component } from "react";
import { SyncLoader } from "react-spinners";

export default class LoadingAnimation extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const style = {
      position: "absolute",
      display: "flex",
      justifyContent: "center",

      top: "20vw",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "white",
    };

    return (
      <SyncLoader
        css={this.props.style ?? style}
        sizeUnit={"px"}
        size={15}
        color={"#08B3C3"}
      />
    );
  }
}
