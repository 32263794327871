import React, { Component, useState } from "react";
import { Button } from "reactstrap";
import StandardInput from "../../../form_components/StandardInput/StandardInput";
import validationUtils from "../../../utilities/validationUtils";
import Axios, { NodeAxios } from "../../../utilities/apiUtils";
import showNotification from "../../../utilities/notificationUtils";
import ResetPasswordValidation from "./ResetPasswordValidation";
import queryString from "query-string";
import { useHistory, useParams } from "react-router-dom";
import AuthLayout from "../../../containers/AuthLayout/AuthLayout";

export const ResetPassword = () => {
    //@ts-ignore
    const { resetCode } = useParams();
    let params = queryString.parse(window.location.search)
    let email = params.email;

    const history = useHistory();

    const [formData, setFormData] = useState({
        password: ""
    })
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const onChange = (value: any, name: string) => {
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!validationUtils.checkFormValidity(formData, ResetPasswordValidation)) {
            setShowError(true);
            return;
        }
        setShowError(false);
        setIsLoading(true);
        NodeAxios.post(`/auth/resetPassword/${resetCode}`, { ...formData, email })
            .then((response) => {
                setIsLoading(false);
                showNotification("success", "Password changed successfully.");
                history.push("/auth/login");
            })
            .catch((error) => {
                setIsLoading(false);
                showNotification("error", error.response.data.message);
            });
    };
    return (
        <AuthLayout>
            {/* <img src={logo} height={} /> */}

            <form onSubmit={onSubmit}>
                <h2 className="mb-4">Reset Password</h2>
                <div className="mb-3">
                    <StandardInput
                        value={formData.password}
                        type="password"
                        name="password"
                        placeholder="Your password"
                        onChange={onChange}
                        validations={ResetPasswordValidation.password}
                        showError={showError}
                    />
                </div>
                <div>
                    <Button
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                        className="login-button w-100 btn-lg"
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm text-light mr-2"
                                    role="status"
                                ></span>
                            ) : null}
                            Reset Password
                        </div>
                    </Button>
                </div>
            </form>
        </AuthLayout>
    );
}



export default ResetPassword;
