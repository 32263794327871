import React, { Component, useEffect, useState } from "react";
import { Button } from "reactstrap";
import StandardInput from "../../../form_components/StandardInput/StandardInput";
// import "./login.scss";
import validationUtils from "../../../utilities/validationUtils";
import VerifyOTPValidation from "./VerifyOTPValidation";
import Axios, { AxiosCoach, NodeAxios } from "../../../utilities/apiUtils";
import showNotification from "../../../utilities/notificationUtils";
import { setAccessToken } from "../../../utilities/tokenUtils";
import LoadingAnimation from "../../../utilities/LoadingAnimation";
import { useHistory } from "react-router-dom";
import AuthLayout from "../../../containers/AuthLayout/AuthLayout";


export const VerifyOTP = () => {
    const history = useHistory();

    const [formData, setFormData] = useState({
        otp: "",
    })
    const [email, setEmail] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState(false);

    const onChange = (value: any, name: string) => {
        setFormData({ ...formData, [name]: value });
    };

    const sendOTP = async () => {
        try {
            let profile = await AxiosCoach.get('/api/user/me')
            await AxiosCoach.get(`generateOtp`, {
                params: {
                    email: profile.data.data.email
                }
            })
        } catch (err) {

        }
        setIsLoading(false);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        console.log(formData, VerifyOTPValidation);
        if (!validationUtils.checkFormValidity(formData, VerifyOTPValidation)) {
            setShowError(true);
            return;
        }
        setShowError(false);
        setIsLoading(true);
        let data = { otpnum: formData.otp, email: email };

        NodeAxios.post("/auth/validateOtp", data)
            .then((response) => {
                history.push("/");
            })
            .catch((error) => {
                setIsLoading(false);
                showNotification("error", error.response.data.message);
            });
    };

    useEffect(() => {
        sendOTP();
    }, [])

    if (isLoading) {
        return <div className="login-container"><LoadingAnimation /></div>
    }
    return (
        <AuthLayout>
        <div className="d-flex justify-content-center vh-100 w-100 align-items-center">
            {/* <img src={logo} height={} /> */}

            <form onSubmit={onSubmit}>
                <h2 className="mb-4">Verify OTP</h2>
                <div className="mb-3">
                    <StandardInput
                        value={formData.otp}
                        type="text"
                        placeholder="Enter OTP"
                        name="otp"
                        onChange={onChange}
                        validations={VerifyOTPValidation.otp}
                        showError={showError}
                    />
                </div>
                <div>
                    <Button
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                        className="login-button w-100 btn-lg"
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm text-light mr-2"
                                    role="status"
                                ></span>
                            ) : null}
                            Verify
                        </div>
                    </Button>
                </div>
            </form>
        </div>
        </AuthLayout>
    );
}

export default VerifyOTP;
