import ValidationEnum from "../../../enums/ValidationsEnum";

export default {
  email: [
    {
      type: ValidationEnum.REQUIRED,
      message: "Email is required",
    },
    {
      type: ValidationEnum.PATTERN,
      message: "Invalid Email id",
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ]  
};
