import ValidationEnum from "../../../enums/ValidationsEnum";
const otpLength = 6;
export default {
    otp: [
        {
            type: ValidationEnum.REQUIRED,
            message: "OTP is required.",
        },
        {
            type: ValidationEnum.PATTERN,
            value: new RegExp(`^[0-9]{${otpLength}}$`),
            message: "Invalid OTP.",
        },
    ],
};
