import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getAccessToken } from "../utilities/tokenUtils";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = getAccessToken();  
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Route {...rest} component={Component} />
        ) : (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
