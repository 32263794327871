import React, { Component, useState } from "react";
import { Button } from "reactstrap";
import StandardInput from "../../../form_components/StandardInput/StandardInput";
import "./login.scss";
import validationUtils from "../../../utilities/validationUtils";
import LoginValidation from "./LoginValidation";
import Axios, { NodeAxios } from "../../../utilities/apiUtils";
import showNotification from "../../../utilities/notificationUtils";
import { setAccessToken } from "../../../utilities/tokenUtils";
import { Link } from "react-router-dom";
import AuthLayout from "../../../containers/AuthLayout/AuthLayout";

export const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const onChange = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!validationUtils.checkFormValidity(formData, LoginValidation)) {
      setShowError(true);
      return;
    }
    setShowError(false);
    setIsLoading(true);
    NodeAxios.post("/corporate-panel/auth/login", formData)
      .then((response) => {
        setAccessToken(response.data.data.accessToken);
        window.location.href = "/";

      })
      .catch((error) => {
        setIsLoading(false);
        showNotification("error", error.response.data.message);
      });
  };
  return (
    <AuthLayout>
      {/* <img src={logo} height={} /> */}

      <form onSubmit={onSubmit}>
        <h2 className="mb-4">Corporate Admin Login</h2>
        <div className="mb-3">
          <StandardInput
            value={formData.email}
            type="text"
            placeholder="Your email"
            name="email"
            onChange={onChange}
            validations={LoginValidation.email}
            showError={showError}
          />
        </div>
        <div className="mb-3">
          <StandardInput
            value={formData.password}
            type="password"
            name="password"
            placeholder="Your password"
            onChange={onChange}
            validations={LoginValidation.password}
            showError={showError}
          />
        </div>
        <div>
          <Button
            color="primary"
            disabled={isLoading}
            type="submit"
            className="login-button w-100 btn-lg"
          >
            <div className="d-flex justify-content-center align-items-center">
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm text-light mr-2"
                  role="status"
                ></span>
              ) : null}
              Login
            </div>
          </Button>
        </div>
        <div className="mt-3 d-flex justify-content-center w-100">
          <div>
            <Link to="/auth/forgot-password">
              Forgot Password
            </Link>
          </div>

        </div>
      </form>
    </AuthLayout>
  );
}


export default Login;
