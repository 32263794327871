export default {
    REQUIRED: "Required",
    MAXLENGTH : "MaxLength",
    MINLENGTH : "MinLength",
    PATTERN : "Pattern",
    COMPAREVALUE : "CompareValue",
    RSAID :"RSAidnumber",
    MIN : "Min",
    MAX : "Max",
    PHONENUMBER : "PhoneNumber"
  };
  