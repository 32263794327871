import ValidationEnum from "../../../enums/ValidationsEnum";

export default {
  email: [
    {
      type: ValidationEnum.REQUIRED,
      message: "Email is required",
    },
    {
      type: ValidationEnum.PATTERN,
      message: "Invalid Email id",
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ],
  password: [
    {
      type: ValidationEnum.REQUIRED,
      message: "Password is required",
    },
  ],
  firstName: [
    {
      type: ValidationEnum.REQUIRED,
      message: "First Name is required.",
    },   
    {
      type: ValidationEnum.MAXLENGTH,
      value: 25,
      message: "Only 25 characters are allowed in First Name.",
    },
  ],
  lastName: [
    {
      type: ValidationEnum.REQUIRED,
      message: "Last Name is required.",
    },   
    {
      type: ValidationEnum.MAXLENGTH,
      value: 25,
      message: "Only 25 characters are allowed in Last Name.",
    },
  ],
  phoneNo: [
    {
      type: ValidationEnum.REQUIRED,
      message: "Phone number is required.",
    },
    {
      type : ValidationEnum.PHONENUMBER,
      message: "Invalid phone number.",
    }
  ],
};
